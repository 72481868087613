import { makeStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import { isIE, browserVersion } from "react-device-detect";

export const useAutoCompleteStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    background: theme.palette.common.white,
  },
  input: {
    display: "flex",
    padding: 0,
  },
  valueContainer: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
    alignItems: "center",
    whiteSpace: "nowrap",
    // width: (props) => (props?.adornment ? "64%" : ""),
    height: (props) => (props?.isAdornment ? theme.spacing(3) : ""),
    "& p": {
      position: "absolute",
      // position: (props) => (props?.isAdornment ? "absolute" : ""),
      width: (props) => (props?.isAdornment ? "80%" : ""),
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  chip: {
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: isIE && browserVersion === "11.0" ? "relative" : "absolute",
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 2,
    marginTop: theme.spacing(2),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  label: {
    position: "absolute",
    top: theme.spacing(-1),
    left: theme.spacing(3),
    padding: theme.spacing(0, 1),
    color: theme.palette.text.secondary,
    fontSize: "0.8rem",
    background: theme.palette.common.white,
  },
  // withAdornmentContainer: {
  //   display: "flex",
  // },
  // withAdornment: {
  //   width: "64%",
  // },
  adornment: {
    position: "absolute",
    top: "0",
    width: "36%",
    right: "0",
    bottom: "0",
    maxWidth: "200px",
    background: "#a0a0a01c",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    borderLeft: "none",
    cursor: "pointer",
  },
  focusedAdornment: {
    borderColor: "#01A650",
    borderWidth: "2px",
  },
  errorAdornment: {
    borderColor: "#E45532",
    borderWidth: "1px",
  },
}));
