import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import Creatable from "react-select/creatable";
import Typography from "@material-ui/core/Typography";
import NoSsr from "@material-ui/core/NoSsr";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { ValidatorComponent } from "react-material-ui-form-validator";
import { getUserName } from "../../../../utils/user";
import { getOrganizationName } from "../../../../utils/organization";
import { useAutoCompleteStyles } from "../../styles/AutoCompleteSelectStyles";
import useDebounce from "../../../../utils/useDebouncer";
import { Box, Tooltip } from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";

const StaffOrContractor = ({ type }) =>
  type ? (
    <Tooltip title={type} aria-label={type} placement="left">
      <Typography component="span" color="textSecondary">
        {type === "staff" ? "[S] " : type === "contractor" ? "[C] " : ""}
      </Typography>
    </Tooltip>
  ) : (
    ""
  );
const NoOptionsMessage = ({ selectProps, innerProps, children }) => {
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
    >
      {selectProps.inputValue ? children : "Start typing to select.."}
    </Typography>
  );
};

const inputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
};

const Option = (props) => {
  const {
    innerRef,
    isFocused,
    isDisabled,
    isSelected,
    innerProps,
    children,
    data,
  } = props;
  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
        color: isDisabled ? "#aaa" : "",
      }}
      title={typeof children === "string" ? children : ""}
      {...innerProps}
    >
      {data.nextLine ? (
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          flexDirection="column"
        >
          <Typography>
            <StaffOrContractor type={data?.uType} />
            {children}
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="caption" color="textSecondary">
              {data?.ref}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {data?.comp}
            </Typography>
          </Box>
        </Box>
      ) : data.sideAndDown ? (
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          flexDirection="column"
        >
          <Box display="flex" justifyContent="space-between">
            <Typography>{children}</Typography>
            <Typography variant="caption" color="textSecondary">
              {data.comp}
            </Typography>
          </Box>
          <Typography variant="caption" color="textSecondary">
            {data?.ref}
          </Typography>{" "}
        </Box>
      ) : data.ref && data?.ref !== "hub" ? (
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>{children}</Typography>
          <Typography variant="caption" color="textSecondary">
            {data.ref}
          </Typography>
        </Box>
      ) : (
        children
      )}
    </MenuItem>
  );
};

const Placeholder = (props) => {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color={selectProps.isValid ? "textSecondary" : `error`}
      style={{
        marginLeft: 12,
      }}
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
};

const SingleValue = ({ selectProps, innerProps, children }) => {
  return (
    <Typography
      className={selectProps.classes.singleValue}
      {...innerProps}
      style={{ color: selectProps.isValid ? "#3D4548" : "#E45532" }}
    >
      {children}
    </Typography>
  );
};

const ValueContainer = ({ selectProps, children }) => {
  return (
    <div
      className={selectProps.classes.valueContainer}
      style={{ paddingLeft: 12 }}
    >
      {children}
    </div>
  );
};

// function MultiValue({ children, selectProps, isFocused, removeProps }) {
//   return (
//     <Chip
//       tabIndex={-1}
//       label={children}
//       className={classNames(selectProps.classes.chip, {
//         [selectProps.classes.chipFocused]: isFocused,
//       })}
//       onDelete={removeProps.onClick}
//       deleteIcon={<CancelIcon {...removeProps} />}
//     />
//   );
// }

const Menu = ({ selectProps, innerProps, children }) => {
  return (
    <Paper
      square
      className={selectProps.classes.paper}
      style={{
        zIndex: 100,
      }}
      {...innerProps}
    >
      {children}
    </Paper>
  );
};

const customComponents = {
  // Control,
  Menu,
  // MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const IntegrationReactSelect = ({
  remote,
  defaultValueSingle,
  data,
  defaultValue,
  props,
  optionKeys,
  single: sgl,
  label,
  placeholder,
  rtl,
  disabled,
  error,
  required,
  helperText,
  validators,
  errorMessages,
  updateValues,
  extraLabel,
  extraNestedLabel,
  createLabel,
  isValid,
  expired,
  getAllDataFromOption = false,
  adornment = false, // has adornment or not
  isAdornment = false, // is adornment or not
  isClearable = true,
  isSearchable = true,
}) => {
  const locale = useSelector(({ core }) => core.locale);
  const classes = useAutoCompleteStyles({ isAdornment, adornment });
  const [single, setSingle] = useState(null);
  const [multi, setMulti] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [inputChange, setInputChange] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const debounce = useDebounce(inputChange, 1500);
  useEffect(() => {
    if (debounce) {
      remote(inputChange);
    }
  }, [debounce]);
  useEffect(() => {
    if (typeof remote === "function") remote();
    if (defaultValueSingle && Object.values(defaultValueSingle).length > 0)
      setSingle(defaultValueSingle);
  }, []);
  useEffect(() => {
    setIsLoading(false);
  }, [data]);
  useEffect(() => {
    setSingle(defaultValueSingle);
  }, [defaultValueSingle]);
  useEffect(() => {
    if (defaultValue !== multi) setMulti(defaultValue);
  }, [defaultValue]);
  const handleChange = (name, value) => {
    // console.log(name, value, "name, value", value?.length);
    if (!defaultValueSingle && name === "single") {
      if (value?.length > 0) value = value[0];
      setSingle(value);
    }

    if (name === "multi") setMulti(value);

    if (typeof updateValues === "function") updateValues(value);
  };
  const getItemMeta = (key, item) => {
    if (!item) return;
    let meta;
    // loop through key and return the first valid key
    if (Array.isArray(key)) {
      for (let i = 0; i < key.length; i++) {
        const accessor = key[i];
        if (item[accessor]) {
          meta = item[accessor];
          break;
        }
      }
    } else {
      meta = item[key];
    }
    return meta;
  };
  const onInputChange = (value, { action }) => {
    // Fetch records that matches the entered value
    if (typeof remote !== "function") return;
    // clearTimeout(TypeTimeout);

    if (action === "input-change") {
      setIsLoading(true);

      setInputChange(value);
      // TypeTimeout = setTimeout(() => {
      //   remote(value);
      // }, 1500);
      // Fetch all records when menu is closed
    } else if (action === "menu-close") {
      remote();
      setIsLoading(false);
    }
  };

  const isBeforeFunc = (date) => {
    let currDate = moment().format("YYYY-MM-DD");
    let expireContract = moment(date).format("YYYY-MM-DD");
    return moment(expireContract).isBefore(currDate);
  };

  const selectStyles = {
    input: (base) => ({
      ...base,
      "& input": {
        font: "inherit",
      },
      padding: "16px 0px",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 2,
    }),
    control: (base, state) => {
      return {
        ...base,
        boxShadow: state.isFocused ? 0 : 0,
        outline: 0,
        border: isAdornment
          ? "unset"
          : isValid
          ? state.isFocused
            ? "2px solid #01A650"
            : "1px solid rgba(0, 0, 0, 0.23)"
          : `1px solid #E45532`,
        "&:hover": {
          cursor: isAdornment ? "pointer" : "text",
          borderColor: isValid
            ? state.isFocused
              ? "#01A650"
              : "#3D4548"
            : "#E45532",
        },
        padding: isAdornment ? "9px 0" : "",
        width: adornment ? "calc(100% - 200px)" : "100%",
        minWidth: adornment ? "64%" : "100%",
        borderTopRightRadius: adornment ? "0" : "4px",
        borderBottomRightRadius: adornment ? "0" : "4px",
        backgroundColor: isAdornment ? "#7571711c" : "white",
      };
    },
    indicatorsContainer: (base) => ({
      ...base,
      "&:hover": {
        cursor: "pointer",
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: !isValid && "#E45532",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: isValid ? "rgba(0, 0, 0, 0.23)" : "#E45532",
    }),
    clearIndicator: (base) => ({
      ...base,
      color: isValid ? "rgba(0, 0, 0, 0.23)" : "#E45532",
    }),
    multiValue: (styles, { data }) => {
      let obj = { ...styles };
      let isExpired = isBeforeFunc(data?.expiry);
      if (isExpired) {
        obj.backgroundColor = "#fd8f8f";
        obj.color = "#fff";
      }
      return obj;
    },
  };
  const optionsData = [];
  let noDefaultFound = true;

  if (data && data.length) {
    data.map((suggestion) => {
      const options = {
        value:
          optionKeys && optionKeys.value
            ? getItemMeta(optionKeys.value, suggestion)
            : extraLabel && extraLabel !== "staffOrContractor"
            ? suggestion?.user.id
            : suggestion?.id,
        label:
          optionKeys && optionKeys.label
            ? getItemMeta(optionKeys.label, suggestion)
            : suggestion?.user
            ? getUserName({ locale, user: suggestion.user })
            : getOrganizationName({
                locale,
                organization: suggestion,
              }),
      };
      if (suggestion?.isDisabled) options.isDisabled = true;
      if (getAllDataFromOption) options.all = { ...suggestion };
      if (extraNestedLabel) {
        let split = extraNestedLabel.split(".");
        let obj = suggestion[split[0]];
        for (let i = 1; i < split.length; i++) {
          obj = obj[split[i]];
        }
        options.ref = obj;
        // let a = { b: [{ c: 1 }, { d: { e: 'aaa' } }] };
        // let s = "b.1.d.e".split(".");
        // let obj1 = a[s[0]];
        // for (let i = 1; i < s.length; i++) {
        //   obj1 = obj1[s[i]];
        // }
        // options.ref = obj1; returns aaa
      }

      if (extraLabel === "staffOrContractor") {
        options.uId = suggestion?.user?.id || undefined;
        options.phone = suggestion?.user?.phone || undefined;
        options.nextLine = true;
        if (suggestion?.organization?.type === "main") {
          options.ref = suggestion?.reference_indicator || "";
          options.comp = suggestion?.company_number || "";
          options.uType = "staff";
        } else {
          options.ref = suggestion?.organization?.name || "";
          options.comp = suggestion?.contract?.contract_number || "";
          options.uType = "contractor";
        }
      }

      if (extraLabel === "vehicle") {
        options.ref = suggestion?.organization?.display_name || "";
        options.comp = suggestion?.plate_number || "";
        options.sideAndDown = true;
      }

      if (extraLabel === "tripDateTime") {
        // options.comp = suggestion?.time
        //   ? `Time: ${moment(suggestion.time).format("HH:mm")}`
        //   : "";
        options.ref = suggestion?.time
          ? `${moment(suggestion.time).format("MMM DD, HH:mm")}`
          : "";
        // options.nextLine = true;
      }

      if (suggestion?.[expired]) {
        options.expiry = suggestion?.[expired] || "";
        options.oId = suggestion?.organization || "";
      }

      if (suggestion?.[extraLabel]) {
        options[extraLabel] = suggestion?.[extraLabel] || "";
        options.ref =
          typeof suggestion?.[extraLabel] === "string"
            ? suggestion?.[extraLabel]
            : "" || "";
      }

      if (suggestion?.[extraLabel] === "spoke")
        options.hubId = suggestion?.stop_id?.id || "";

      if (
        sgl &&
        single &&
        options.label === single.label &&
        options.value === single.value
      ) {
        noDefaultFound = false;
      }
      optionsData.push(options);
    });
  }
  if (single && sgl && noDefaultFound) {
    optionsData.push(single);
  }
  const callOnFocus = () => setIsFocused(true);
  const callOnBlur = () => setIsFocused(false);
  return (
    // <h1>dddd</h1>
    <>
      <div className={clsx(classes.root)}>
        {/* <div className={clsx(adornment ? classes.withAdornmentContainer : "")}> */}
        {/* <div className={clsx(adornment ? classes.withAdornment : "")}> */}
        <NoSsr>
          {createLabel ? (
            <Creatable
              classes={classes}
              styles={selectStyles}
              textFieldProps={{
                label: label,
                InputLabelProps: {
                  shrink: true,
                },
              }}
              isLoading={isLoading}
              options={optionsData}
              // defaultValue={optionsData[0]}
              value={sgl ? single : multi}
              onChange={(val) => {
                if (sgl) return handleChange("single", val);
                else return handleChange("multi", val);
              }}
              onInputChange={onInputChange}
              placeholder={placeholder}
              isMulti={!single}
              isClearable={isClearable}
              isRtl={rtl || false}
              isDisabled={disabled || false}
              error={error}
              required={required}
              helperText={helperText}
              validators={validators}
              errorMessages={errorMessages}
              formatCreateLabel={(val) => {
                return `Add ${createLabel} '${val}'`;
              }}
              filterOption={(options, filter, current_values) => {
                return options;
              }}
              isValid={isValid}
              isSearchable={isSearchable}
              onFocus={callOnFocus}
              onBlur={callOnBlur}
              {...props}
              // menuIsOpen={true}
            />
          ) : sgl ? (
            <Select
              classes={classes}
              styles={selectStyles}
              textFieldProps={{
                label: label,
                InputLabelProps: {
                  shrink: true,
                },
              }}
              isLoading={isLoading}
              options={optionsData}
              components={customComponents}
              // defaultValue={optionsData[0]}
              value={single}
              onChange={(val) => handleChange("single", val)}
              onInputChange={onInputChange}
              placeholder={placeholder}
              single
              isClearable={isClearable}
              isRtl={rtl || false}
              isDisabled={disabled || false}
              error={error}
              required={required}
              helperText={helperText}
              validators={validators}
              errorMessages={errorMessages}
              filterOption={(options, filter, current_values) => {
                return options;
              }}
              isValid={isValid}
              isSearchable={isSearchable}
              onFocus={callOnFocus}
              onBlur={callOnBlur}
              {...props}
              // menuIsOpen={true}
            />
          ) : (
            <Select
              classes={classes}
              styles={selectStyles}
              textFieldProps={{
                label: label,
                InputLabelProps: {
                  shrink: true,
                },
              }}
              isLoading={isLoading}
              options={optionsData}
              components={customComponents}
              // components={components}
              value={multi}
              onChange={(val) => handleChange("multi", val)}
              onInputChange={onInputChange}
              placeholder={placeholder}
              isDisabled={disabled || false}
              isMulti
              filterOption={(options, filter, current_values) => {
                return options;
              }}
              isValid={isValid}
              isSearchable={isSearchable}
              onFocus={callOnFocus}
              onBlur={callOnBlur}
              {...props}
              // menuIsOpen={true}
            />
          )}
        </NoSsr>
        {/* </div> */}
        {adornment && (
          <div
            className={clsx(
              classes.adornment,
              isFocused && classes.focusedAdornment,
              !isValid && classes.errorAdornment
            )}
          >
            {adornment}
          </div>
        )}
        {/* </div> */}
        {label && (defaultValueSingle || defaultValue || single || multi) && (
          <div className={classes.label}>{label}</div>
        )}
      </div>
    </>
  );
};

class AutocompleteInput extends ValidatorComponent {
  renderValidatorComponent() {
    const { isValid } = this.state;
    let serverSideError = this.props.error;

    return (
      <>
        <IntegrationReactSelect
          {...this.props}
          isValid={!serverSideError && isValid}
        />
        {this.errorText(this.props.helperText)}
      </>
    );
  }

  errorText(val) {
    const { isValid } = this.state;
    if (isValid && !val) {
      return null;
    }
    return (
      <Typography
        color="error"
        variant="caption"
        display="block"
        gutterBottom
        style={{ paddingTop: "2px", paddingLeft: "14px" }}
      >
        {val ? val : this.getErrorMessage()}
      </Typography>
    );
  }
}

const ReactSelectComp = (props) => {
  if (props.notFormComponent)
    return <IntegrationReactSelect {...props} isValid={true} />;
  else return <AutocompleteInput {...props} />;
};
export default ReactSelectComp;
