import config from "../config/config";

export function getUserName({ user, locale }) {
  if (!user) return;

  let name = "";
  if (
    locale &&
    locale !== "en" &&
    (user.given_names_alt || user.last_name_alt)
  ) {
    name = user.given_names_alt ? user.given_names_alt : "";
    name = user.middle_name_alt ? name + " " + user.middle_name_alt : name;
    name = user.last_name_alt ? name + " " + user.last_name_alt : name;
  } else if (user.given_names || user.last_name) {
    name = user.given_names ? user.given_names : "";
    name = user.middle_name ? `${name} ${user.middle_name}` : name;
    name = user.last_name ? `${name} ${user.last_name}` : name;
  }
  return name;
}

export function getUserRole(user) {
  if (user.is_superuser) {
    return "superuser";
  } else if (user.user_type >= 0) {
    const { userTypes } = config;
    const entries = Object.entries(userTypes);

    for (const [role, id] of entries) {
      if (id === user.user_type) {
        return role;
      }
    }
  } else {
    return;
  }
}

export function isPermissionAllowed({ module, user, permission }) {
  return true;
  // if (!user || typeof user !== "object" || !user.permission_list) return false;

  // const { permission_list } = user;
  // if (permission_list && permission_list.length) {
  //   for (let i = 0; i < permission_list.length; i++) {
  //     if (permission_list[i].name === module) {
  //       if (permission_list[i]["can_" + permission]) return true;
  //     }
  //   }
  //   return false;
  // } else {
  //   return false;
  // }
}

export function isOneOfPermissionAllowed({ user, modules, permission }) {
  return true;
  // if (!user || typeof user !== "object" || !user.permission_list) return false;
  // const { permission_list } = user;
  // if (permission_list && permission_list.length && modules && modules.length) {
  //   for (let i = 0; i < permission_list.length; i++) {
  //     for (let m = 0; m < modules.length; m++) {
  //       if (permission_list[i].name === modules[m]) {
  //         if (permission_list[i]["can_" + permission]) return true;
  //       }
  //     }
  //   }
  //   return false;
  // } else {
  //   return false;
  // }
}
